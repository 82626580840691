
import React from 'react';
import { validateUrl } from '../../utils/helpers/validate';
function About() {
    return (
        <div>
            <h1>About</h1>
        </div>
    );
}

export default validateUrl(About);