import { createHashRouter } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Screens from "../js/screens/screens";

const router = createHashRouter([
    {
        path: "/", // 当路径为 "/" 时重定向到 "/login"
        element: <Navigate to="/index" replace />
    },
    {
        path: "/index",
        element: <Screens.App />,
        children: [
            {
                path: "products",
                element: <Screens.Products />,
            },
            {
                path: "about",
                element: <Screens.About />,
            },
            {
                path: "news",
                element: <Screens.News />,
            },
            {
                path: "contact",
                element: <Screens.Contact />,
            },
            {
                path: "*",
                element: <Navigate to="/index" replace />
            }],
    },
    {
        path: "/not-found",
        element: <Screens.NotFound message={null} link="/index" />,
    },
    {
        path: "*",
        element: <Screens.NotFound message={null} link="/index" />,
    }
]);

export default router;