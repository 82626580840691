import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import { useVisibilityInOut } from '../../utils/helpers/myUse';
function VideoText({
    isDarkTheme,
    flag = true,
    VideoTextVideoSet,
    VideoTexts,

}) {
    const [videoSrc, setVideoSrc] = useState('');

    useEffect(() => {

        const getPageLoadSpeed = () => {
            const entries = performance.getEntriesByType('navigation');
            const loadTime = entries.length ? entries[0].loadEventEnd - entries[0].startTime : 0;
            return loadTime;
        };

        const loadTime = getPageLoadSpeed();

        if (isDarkTheme) {
            if (loadTime < 2000) {
                setVideoSrc(VideoTextVideoSet.Dark.Video3);
            } else if (loadTime < 5000) {
                setVideoSrc(VideoTextVideoSet.Dark.Video2);
            } else {
                setVideoSrc(VideoTextVideoSet.Dark.Video1);
            }
        } else {
            if (loadTime < 2000) {
                setVideoSrc(VideoTextVideoSet.Light.Video3);
            } else if (loadTime < 5000) {
                setVideoSrc(VideoTextVideoSet.Light.Video2);
            } else {
                setVideoSrc(VideoTextVideoSet.Light.Video1);
            }
        }
    }, [isDarkTheme, VideoTextVideoSet]);

    const containerRef = useRef(null);
    const isVisible = useVisibilityInOut(containerRef, 0.4, 0.3);

    return (
        <div ref={containerRef} className={`video-text-container ${!flag ? 'flag' : ''}`}>
            {flag ? (
                <>
                    <div className={`video-text-video ${isVisible ? 'slide-in-left' : 'slide-out-left'}`}>
                        <video src={videoSrc} autoPlay loop muted playsInline />
                    </div>
                    <div className={`video-text-text ${isVisible ? 'slide-in-right' : 'slide-out-right'}`}>
                        {VideoTexts()}
                    </div>
                </>
            ) : (
                <>
                    <div className={`video-text-text ${isVisible ? 'slide-in-left' : 'slide-out-left'}`}>
                        {VideoTexts()}
                    </div>
                    <div className={`video-text-video ${isVisible ? 'slide-in-right' : 'slide-out-right'}`}>
                        <video src={videoSrc} autoPlay loop muted playsInline />
                    </div>
                </>
            )}
        </div>
    );
}


export default VideoText;
