
import React from 'react';
import { validateUrl } from '../../utils/helpers/validate';
function News() {
    return (
        <div>
            <h1>News</h1>
        </div>
    );
}

export default validateUrl(News);