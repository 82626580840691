/* All Path Configurations */
import VILLAGE_1280         from './../../assets/videos/village_1280.mp4';
import VILLAGE_1920         from './../../assets/videos/village_1920.mp4';
import VILLAGE_2560         from './../../assets/videos/village_2560.mp4';
import VILLAGE_3840         from './../../assets/videos/village_3840.mp4';
import VILLAGE_5472         from './../../assets/videos/village_5472.mp4';
import VILLAGE2_640         from './../../assets/videos/village2_640.mp4';
import VILLAGE2_960         from './../../assets/videos/village2_960.mp4';
import VILLAGE2_1280        from './../../assets/videos/village2_1280.mp4';
import VILLAGE2_1920        from './../../assets/videos/village2_1920.mov';

import HORIZON_LOGO         from './../../assets/sjgz/HorizonLogo.png';
import HORIZON_LOGO_B       from './../../assets/sjgz/HorizonLogoB.png';
import HORIZON_LOGO_W       from './../../assets/sjgz/HorizonLogoW.png';
import HORIZON_LOGO_Y       from './../../assets/sjgz/HorizonLogoY.png';
import LOGO_TEXT_DRED       from './../../assets/sjgz/logo_text_dred.png';
import LOGO_TEXT_GOLD       from './../../assets/sjgz/logo_text_gold.png';

import TEA_1280             from './../../assets/images/tea_1280.jpg';
import TEA_1920             from './../../assets/images/tea_1920.jpg';
import TEA_4500             from './../../assets/images/tea_4500.jpg';
import TEA2_1280            from './../../assets/images/tea2_1280.jpg';
import TEA2_1920            from './../../assets/images/tea2_1920.jpg';
import TEA2_6000            from './../../assets/images/tea2_6000.jpg';
import TEA3_1280            from './../../assets/images/tea3_1280.jpg';
import TEA3_1920            from './../../assets/images/tea3_1920.jpg';
import TEA3_4729            from './../../assets/images/tea3_4729.jpg';
import TEA4_640             from './../../assets/images/tea4_640.jpg';
import TEA4_1280            from './../../assets/images/tea4_1280.jpg';
import TEA4_1920            from './../../assets/images/tea4_1920.jpg';
import TEA4_4500            from './../../assets/images/tea4_4500.jpg';
import TEA5_640             from './../../assets/images/tea5_640.jpg';
import TEA5_1280            from './../../assets/images/tea5_1280.jpg';
import TEA5_1920            from './../../assets/images/tea5_1920.jpg';
import TEA5_4256            from './../../assets/images/tea5_4256.jpg';
import TEA5_1280_REMOVE_BG  from './../../assets/images/tea5_1280-removebg-preview.png';
import TEA6_640             from './../../assets/images/tea6_640.jpg';
import TEA6_1280            from './../../assets/images/tea6_1280.jpg';
import TEA6_1920            from './../../assets/images/tea6_1920.jpg';
import TEA6_4961            from './../../assets/images/tea6_4961.jpg';
import TEA6_1280_REMOVE_BG  from './../../assets/images/tea6_1280-removebg-preview.png';
import TEA7_640             from './../../assets/images/tea7_640.jpg';
import TEA7_1280            from './../../assets/images/tea7_1280.jpg';
import TEA7_1920            from './../../assets/images/tea7_1920.jpg';
import TEA7_5184            from './../../assets/images/tea7_5184.jpg';
import TEA7_1280_REMOVE_BG  from './../../assets/images/tea7_1280-removebg-preview.png';
import TEA8_640             from './../../assets/images/tea8_640.jpg';
import TEA8_1280            from './../../assets/images/tea8_1280.jpg';
import TEA8_1920            from './../../assets/images/tea8_1920.jpg';
import TEA8_3264            from './../../assets/images/tea8_3264.jpg';
import TEA8_5616            from './../../assets/images/tea8_5616.jpg';
import TEA8_1280_REMOVE_BG  from './../../assets/images/tea8_1280-removebg-preview.png';
import TITLE_1280           from './../../assets/images/title_1280.jpg';
import TITLE_1920           from './../../assets/images/title_1920.jpg';
import TITLE_2276           from './../../assets/images/title_2276.jpg';  

import WECHAT_QRCODE        from './../../assets/sjgz/wechat_qrcode.png';

export const PATHS = {
    SRC:{
        ASSETS:{
            IMAGES:{
                TEA_1280,           
                TEA_1920,           
                TEA_4500,           
                TEA2_1280,          
                TEA2_1920,          
                TEA2_6000,          
                TEA3_1280,          
                TEA3_1920,          
                TEA3_4729,          
                TEA4_640,           
                TEA4_1280,          
                TEA4_1920,          
                TEA4_4500,          
                TEA5_640,           
                TEA5_1280,          
                TEA5_1920,          
                TEA5_4256,          
                TEA5_1280_REMOVE_BG,
                TEA6_640,           
                TEA6_1280,          
                TEA6_1920,          
                TEA6_4961,          
                TEA6_1280_REMOVE_BG,
                TEA7_640,           
                TEA7_1280,          
                TEA7_1920,          
                TEA7_5184,          
                TEA7_1280_REMOVE_BG,
                TEA8_640,           
                TEA8_1280,          
                TEA8_1920,          
                TEA8_3264,          
                TEA8_5616,          
                TEA8_1280_REMOVE_BG,
                TITLE_1280,         
                TITLE_1920,         
                TITLE_2276,         
            },
            SJGZ:{
                HORIZON_LOGO:       HORIZON_LOGO,
                HORIZON_LOGO_B:     HORIZON_LOGO_B,
                HORIZON_LOGO_W:     HORIZON_LOGO_W,
                HORIZON_LOGO_Y:     HORIZON_LOGO_Y,
                LOGO_TEXT_DRED:     LOGO_TEXT_DRED,
                LOGO_TEXT_GOLD:     LOGO_TEXT_GOLD,
                WECHAT_QRCODE:      WECHAT_QRCODE,
            },
            VIDEOS:{
                VILLAGE_1280:       VILLAGE_1280,
                VILLAGE_1920:       VILLAGE_1920,
                VILLAGE_2560:       VILLAGE_2560,
                VILLAGE_3840:       VILLAGE_3840,
                VILLAGE_5472:       VILLAGE_5472,
                VILLAGE2_640:       VILLAGE2_640,
                VILLAGE2_960:       VILLAGE2_960,
                VILLAGE2_1280:      VILLAGE2_1280,
                VILLAGE2_1920:      VILLAGE2_1920,
            },
        }
    }
};